<template>
  <div>
    <p aria-live="polite">
      {{ carouselRefreshMessage }}
    </p>
    <CustomCarouselSkeleton v-if="$fetchState.pending" />
    <CustomCarousel
      v-else-if="properties.length"
      :items="properties"
      showcases
    />
    <p v-else class="text-center text-xl">
      Non sono presenti immobili per la tua posizione.
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex'

// Static assets
import { SectionsMap } from '~/assets/js/sections'

// Components
import CustomCarousel from '~/components/CustomCarousel.vue'
import CustomCarouselSkeleton from '~/components/CustomCarouselSkeleton.vue'

export default {
  components: {
    CustomCarousel,
    CustomCarouselSkeleton,
  },
  // props: {
  //   section: {
  //     type: String,
  //     required: true,
  //   },

  //   geo: {
  //     type: Object,
  //     default() {
  //       return null
  //     },
  //   },
  // },

  fetchOnServer: false,

  data() {
    return {
      properties: [],
    }
  },
  async fetch() {
    const { section, geo } = this

    const sectionSlug = section?.slug ?? 'venditaimmobili'
    try {
      const res = await this.$axios.get('/_api/api/v2/showcases', {
        params: {
          ...(geo
            ? {
                ...(geo.comune ? { city_id: geo.comune } : {}),
                ...(geo.provincia ? { province_id: geo.provincia } : {}),
              }
            : {}),
          section: SectionsMap[sectionSlug],
        },
      })

      this.properties = res.data.data.slice(0, 5)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  },

  computed: {
    ...mapState('search', ['section', 'geo']),
    carouselRefreshMessage() {
      if (!this.$fetchState.pending && this.properties.length) {
        return `Nuovi immobili in evidenza ${
          this.geo
            ? 'per ' + (this.geo.comuneNome || this.geo.provinciaNome)
            : ''
        }`
      }

      return ''
    },
  },

  watch: {
    section: '$fetch',
    geo: '$fetch',
  },
}
</script>
