<template>
  <div class="flex flex-1 justify-between relative">
    <div class="flex flex-col gap-12">
      <OSkeleton width="100px" height="21px" :rounded="false" />
      <OSkeleton width="360px" height="56px" :rounded="false" />
      <OSkeleton width="360px" height="28px" :rounded="false" />
      <OSkeleton width="360px" height="21px" :rounded="false" />
      <OSkeleton width="360px" height="63px" :rounded="false" />
      <OSkeleton width="140px" height="50px" :rounded="false" />
    </div>
    <div class="w-full ml-20 flex items-center">
      <OSkeleton root-class="w-60" height="250px" :rounded="false" />
      <OSkeleton width="350px" height="440px" :rounded="false" />
      <OSkeleton
        root-class="w-full"
        height="250px"
        :rounded="false"
      ></OSkeleton>
    </div>
    <div class="absolute bottom-0 right-0">
      <OSkeleton width="200px" height="40px" :rounded="false" />
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="postcss">
@import '~/assets/css/components/skeleton';
</style>
